var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-default",
    attrs: {
      "to": {
        name: _vm.AnnouncementManagePath.name
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward"
  }), _vm._v(" Manage Announcement ")])], 1), _vm.item ? _c('b-col', [_c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "card mt-4"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(" Edit Announcement (" + _vm._s(this.form.title) + ") ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticClass: "bg-info text-dark"
  }, [_vm._v("(*) Kolom berlabel bintang wajib diisi.")])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title *"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "title",
      "type": "text",
      "placeholder": "input title",
      "required": ""
    },
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Content *"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "content",
      "placeholder": "input content",
      "rows": "6",
      "max-rows": "12",
      "required": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Publisher"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "id",
      "placeholder": "Publisher",
      "open-direction": "bottom",
      "options": _vm.publisherSearchOptions,
      "searchable": true,
      "loading": _vm.isPublisherSearch,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": false,
      "preselect-first": false,
      "multiple": false,
      "taggable": false,
      "required": ""
    },
    on: {
      "search-change": _vm.publisherFind
    },
    model: {
      value: _vm.form.publisher,
      callback: function ($$v) {
        _vm.$set(_vm.form, "publisher", $$v);
      },
      expression: "form.publisher"
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.form.is_active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_active", $$v);
      },
      expression: "form.is_active"
    }
  }, [_vm._v(" Is Active ")])], 1)], 1)], 1), _c('br'), _c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }